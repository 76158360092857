import React from 'react'
import BellIcon from "../../../../assets/svgs/BellIcon"
import {IconButton, SvgIcon, Tooltip} from '@mui/material'
import mixpanel from 'mixpanel-browser';


const Notification = () => {

    const handleNotificationClick = () => {
        mixpanel.track('Notification Clicked');
    };

    return (
        <>
            <Tooltip title="Notifications">
                <IconButton color="inherit" onClick={handleNotificationClick}>
                    <SvgIcon>
                        <BellIcon/>
                    </SvgIcon>
                </IconButton>
            </Tooltip>
        </>
    )
}

export default Notification