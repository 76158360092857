import React from 'react'
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import secureLocalStorage from "react-secure-storage";

const MyAssets = ({style, width, height, className, ...props}) => {
    const window = useSelector(state => state?.getWindowSize);
    const strokeColor = window.color === 'My Assets' ? "#070707" : "#737373";
    return (
        <>
            {JSON.parse(secureLocalStorage.getItem('datum_count')) ?
                <svg width={width || "20"}
                     height={height || "20"}
                     viewBox="0 0 28 28"
                     fill="none"
                     xmlns="http://www.w3.org/2000/svg"
                     style={{marginLeft: -3, ...style}}
                     className={className}
                >
                    <path
                        d="M24.43 6.17167H25.8883C25.83 5.99667 25.7833 5.83334 25.725 5.68167C25.41 4.88834 25.0133 4.13 24.5933 3.38334C24.15 2.59 23.4617 2.1 22.5867 1.86667C22.19 1.76167 21.7817 1.75 21.3733 1.75C16.3683 1.75 11.375 1.75 6.37 1.75C5.81 1.75 5.27334 1.84334 4.76 2.07667C4.095 2.39167 3.57 2.84667 3.22 3.51167C2.765 4.375 2.28667 5.215 1.83167 6.07834C1.80833 6.11334 1.79666 6.14833 1.77333 6.195H3.37167V21.4317H1.75C1.75 21.4317 1.78499 21.5133 1.80833 21.5367C2.27499 22.3883 2.74166 23.24 3.19666 24.1033C3.54666 24.7683 4.07167 25.235 4.74833 25.55C4.97 25.655 5.20333 25.725 5.44833 25.8067C5.05166 26.2383 4.655 26.6817 4.24667 27.125C4.28167 27.125 4.29333 27.125 4.30499 27.125C5.14499 27.1017 5.985 27.0667 6.825 27.0317C6.87166 27.0317 6.91833 26.9967 6.94166 26.9733C7.28 26.635 7.63 26.285 7.96834 25.935C8.015 25.8883 8.06167 25.865 8.13167 25.865C12.0517 25.865 15.9833 25.865 19.9033 25.865C19.9617 25.865 20.02 25.9 20.0667 25.935C20.4167 26.285 20.7667 26.635 21.1167 26.985C21.1517 27.02 21.1983 27.0433 21.245 27.055C22.0733 27.09 22.9017 27.1133 23.73 27.1367C23.7533 27.1367 23.7767 27.1367 23.8233 27.1367C23.4033 26.67 22.9833 26.215 22.5633 25.7483C22.61 25.7367 22.6333 25.725 22.6567 25.7133C23.4967 25.4683 24.15 24.9783 24.57 24.2083C24.9083 23.6017 25.2117 22.9717 25.515 22.3417C25.655 22.05 25.76 21.7233 25.8883 21.3967H24.43V6.16V6.17167ZM23.0533 22.26L22.8667 22.6333C22.715 22.925 22.5867 23.1817 22.4583 23.4383C22.4233 23.5083 22.4117 23.5433 22.2483 23.5783C22.12 23.6017 21.9917 23.6133 21.8633 23.6133H12.4717H5.845C5.71667 23.6133 5.61166 23.59 5.53 23.5317C5.27333 23.1117 5.01666 22.6683 4.77166 22.2367V22.2133V5.285L4.95833 5.005C5.16833 4.66667 5.34333 4.36333 5.50667 4.06C5.72833 3.955 5.83334 3.94333 5.92667 3.94333H21.8983C22.225 3.94333 22.3533 3.99 22.4233 4.03667C22.54 4.24667 22.925 4.98167 23.065 5.27333V22.2717L23.0533 22.26Z"
                        fill={strokeColor}/>
                    <path
                        d="M16.7884 13.58C16.45 13.58 15.89 13.3584 15.4234 12.9034C15.155 12.6467 14.875 12.39 14.6067 12.11C14.3617 11.865 14.3384 11.5967 14.5367 11.3867C14.735 11.1767 15.0384 11.1884 15.2834 11.4334C15.54 11.6784 15.785 11.935 16.0417 12.18C16.5784 12.6934 17.255 12.775 17.8734 12.4367C18.5034 12.0867 18.8417 11.34 18.6784 10.6634C18.48 9.78835 17.7917 9.24002 16.9867 9.31002C16.6017 9.34502 16.31 9.52002 16.0417 9.77669C14.98 10.7917 13.9184 11.8067 12.8567 12.8334C12.11 13.545 11.2584 13.8017 10.29 13.4634C9.27503 13.125 8.7267 12.3667 8.5517 11.34C8.35337 10.2317 8.93669 9.14669 9.94003 8.63336C10.9434 8.12002 12.04 8.30669 12.8567 9.11169C13.1017 9.33336 13.335 9.57836 13.58 9.80002C13.825 10.045 13.8484 10.3367 13.65 10.5234C13.4517 10.7217 13.1484 10.6984 12.9034 10.4767C12.6584 10.255 12.4367 10.0217 12.1917 9.80002C11.55 9.19336 10.8267 9.12336 10.1617 9.59002C9.48503 10.0684 9.28669 10.9084 9.64836 11.6667C10.0684 12.6117 11.34 13.0084 12.1917 12.1334C13.2067 11.1067 14.2917 10.1384 15.3184 9.12335C16.065 8.41169 16.8934 8.14335 17.885 8.45835C18.9 8.79669 19.4834 9.54336 19.6584 10.57C19.915 12.1334 18.69 13.5684 16.8117 13.5684L16.7884 13.58Z"
                        fill={strokeColor}/>
                </svg>
                :
                <svg
                    width="15"
                    height="18"
                    viewBox="0 0 15 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={style}
                    className={className}
                >
                    <path d="M0.675049 5.89581H10.1191" stroke={window.color === 'My Assets' ? "#161616" : "#737373"}
                          strokeWidth="0.8" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path
                        d="M9.44425 9.04041V10.2196C9.44425 10.3238 9.40871 10.4238 9.34546 10.4975C9.2822 10.5712 9.19641 10.6127 9.10696 10.6127H8.0951C8.00565 10.6127 7.91985 10.5712 7.8566 10.4975C7.79335 10.4238 7.75781 10.3238 7.75781 10.2196V9.04041"
                        stroke={window.color === 'My Assets' ? "#161616" : "#737373"} strokeWidth="0.8"
                        strokeLinecap="round"
                        strokeLinejoin="round"/>
                    <path
                        d="M3.03604 9.04041V10.2196C3.03604 10.3238 3.00051 10.4238 2.93725 10.4975C2.874 10.5712 2.78821 10.6127 2.69876 10.6127H1.6869C1.59744 10.6127 1.51165 10.5712 1.4484 10.4975C1.38514 10.4238 1.34961 10.3238 1.34961 10.2196V9.04041"
                        stroke={window.color === 'My Assets' ? "#161616" : "#737373"} strokeWidth="0.8"
                        strokeLinecap="round"
                        strokeLinejoin="round"/>
                    <path d="M2.69849 7.46826H3.37306" stroke={window.color === 'My Assets' ? "#161616" : "#737373"}
                          strokeWidth="0.8" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M7.42065 7.46826H8.09523" stroke={window.color === 'My Assets' ? "#161616" : "#737373"}
                          strokeWidth="0.8" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path
                        d="M9.44449 5.89595L8.18388 2.59424C8.15773 2.52439 8.11471 2.4649 8.06006 2.42303C8.00542 2.38116 7.94151 2.3587 7.87611 2.3584H2.91799C2.8526 2.3587 2.78868 2.38116 2.73404 2.42303C2.6794 2.4649 2.63638 2.52439 2.61022 2.59424L1.34961 5.89595V9.04045H9.44449V5.89595Z"
                        stroke={window.color === 'My Assets' ? "#161616" : "#737373"} strokeWidth="0.8"
                        strokeLinecap="round"
                        strokeLinejoin="round"/>
                    <path
                        d="M15.0006 16.6445H5.86793V8.49682C5.86723 8.29596 5.89971 8.09693 5.9635 7.9112C6.02729 7.72547 6.12113 7.55673 6.23961 7.4147C6.35808 7.27267 6.49884 7.16018 6.65376 7.0837C6.80869 7.00723 6.97471 6.96829 7.14226 6.96913H13.7263C13.8938 6.96829 14.0598 7.00723 14.2148 7.0837C14.3697 7.16018 14.5105 7.27267 14.6289 7.4147C14.7474 7.55673 14.8412 7.72547 14.905 7.9112C14.9688 8.09693 15.0013 8.29596 15.0006 8.49682V16.6445Z"
                        fill="#F5F5F5"/>
                    <path d="M6.80835 9.53949H14.06" stroke={window.color === 'My Assets' ? "#161616" : "#737373"}
                          strokeWidth="0.8" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M6.80835 11.8977H14.06" stroke={window.color === 'My Assets' ? "#161616" : "#737373"}
                          strokeWidth="0.8" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path
                        d="M14.06 15.4355H6.80836V9.14654C6.8078 8.99151 6.83359 8.83788 6.88425 8.69452C6.9349 8.55116 7.00941 8.42091 7.10348 8.31128C7.19755 8.20166 7.30932 8.11482 7.43234 8.05579C7.55535 7.99676 7.68718 7.96671 7.82022 7.96736H13.0482C13.1812 7.96671 13.313 7.99676 13.436 8.05579C13.5591 8.11482 13.6708 8.20166 13.7649 8.31128C13.859 8.42091 13.9335 8.55116 13.9841 8.69452C14.0348 8.83788 14.0606 8.99151 14.06 9.14654V15.4355Z"
                        stroke={window.color === 'My Assets' ? "#161616" : "#737373"} strokeWidth="0.8"
                        strokeLinecap="round"
                        strokeLinejoin="round"/>
                    <path
                        d="M14.06 15.4351V16.6143C14.06 16.7186 14.0244 16.8185 13.9612 16.8922C13.8979 16.966 13.8121 17.0074 13.7227 17.0074H12.7108C12.6214 17.0074 12.5356 16.966 12.4723 16.8922C12.4091 16.8185 12.3735 16.7186 12.3735 16.6143V15.4351"
                        stroke={window.color === 'My Assets' ? "#161616" : "#737373"} strokeWidth="0.8"
                        strokeLinecap="round"
                        strokeLinejoin="round"/>
                    <path
                        d="M8.49478 15.4351V16.6143C8.49478 16.7186 8.45925 16.8185 8.39599 16.8922C8.33274 16.966 8.24695 17.0074 8.1575 17.0074H7.14564C7.05618 17.0074 6.97039 16.966 6.90714 16.8922C6.84389 16.8185 6.80835 16.7186 6.80835 16.6143V15.4351"
                        stroke={window.color === 'My Assets' ? "#161616" : "#737373"} strokeWidth="0.8"
                        strokeLinecap="round"
                        strokeLinejoin="round"/>
                    <path
                        d="M8.57966 14.256C8.85908 14.256 9.08559 13.9921 9.08559 13.6664C9.08559 13.3408 8.85908 13.0768 8.57966 13.0768C8.30024 13.0768 8.07373 13.3408 8.07373 13.6664C8.07373 13.9921 8.30024 14.256 8.57966 14.256Z"
                        fill="#737373"/>
                    <path
                        d="M12.2891 14.256C12.5686 14.256 12.7951 13.9921 12.7951 13.6664C12.7951 13.3408 12.5686 13.0768 12.2891 13.0768C12.0097 13.0768 11.7832 13.3408 11.7832 13.6664C11.7832 13.9921 12.0097 14.256 12.2891 14.256Z"
                        fill="#737373"/>
                </svg>
            }
        </>

    )
}

MyAssets.propTypes = {
    fill: PropTypes.string
};

export default MyAssets
