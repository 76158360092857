import React from 'react';
import {makeStyles} from "@mui/styles";
import Lottie from 'react-lottie';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {animationData} from "../animations/SplashScreen.js";

const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        left: 0,
        padding: theme.spacing(3),
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 2000,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2), // Less padding on smaller screens
        }
    }
}));

const SplashScreen = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detects mobile screens

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        renderer: 'svg'
    };

    return (
        <div className={classes.root}>
            <Lottie
                options={defaultOptions}
                height={isMobile ? 250 : 500} // Smaller height on mobile
                width={isMobile ? 250 : 500}  // Smaller width on mobile
            />
        </div>
    );
};

export default SplashScreen;
