import CryptoJS from 'crypto-js';

// const decryptedData = (response) => {
//     let secret = new fernet.Secret('J0xhbmRlZCBvbiBtYXJzIG1lIGhpJw==');
//     // const textEncoder = new TextEncoder();
//     // let iv = "huyhfrtggtesdqwg"
//     // const ivArray = textEncoder.encode(iv);
//     let toke = new fernet.Token({
//         secret: secret,
//         token: response?.data,
//         ttl: 0,
//     });
//
//
//
//     return JSON.parse(toke.decode())
// }

const decryptedData = (response) => {
    const secretKey = 'J0xhbmRlZCBvbiBtYXJzIG1lIGhpJw=='; // Replace with your secret key
    const iv = 'huyhfrtggtesdqwg';
    // Parse the secret key and IV into CryptoJS format
    const key = CryptoJS.enc.Utf8.parse(secretKey);
    const ivHex = CryptoJS.enc.Utf8.parse(iv);
    let decrypted = CryptoJS.AES.decrypt(
        response?.data,
        key,
        {iv: ivHex, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7}
    );

    // Convert decrypted data to Utf8 format and return as plaintext

    return decrypted.toString(CryptoJS.enc.Utf8);
};

export default decryptedData