import React from "react";
import {Button, Card, CardContent, Collapse, Typography} from "@mui/material";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null, timestamp: null };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }


    componentDidCatch(error, errorInfo) {
        console.error("ErrorBoundary caught an error=", error, errorInfo, error?.name);
        // if (error?.name === 'ChunkLoadError') {
        //   return  window.location.reload();
        // }
        const timestamp = new Date().toLocaleString();
        this.setState({ error, errorInfo, timestamp });
    }

    handleRetry = () => {
        window.location.reload();
    };

    render() {
        if (this.state.hasError) {
            return (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", minHeight: "100vh", backgroundColor: "#f5f5f5", padding: "24px" }}>
                    <Card sx={{ maxWidth: 500, textAlign: "center", padding: 4 }}>
                        <CardContent>
                            <img src="https://media.giphy.com/media/26xBwdIuRJiAIqHwA/giphy.gif" alt="Error" style={{ width: "150px", marginBottom: "16px" }} />
                            <Typography variant="h5" color="error" gutterBottom>
                                Oops, something went wrong!
                            </Typography>
                            <Typography variant="body2" color="textSecondary" paragraph>
                                An unexpected error has occurred. Please try again or contact support if the problem persists.
                            </Typography>
                            {this.state.error && (
                                <Collapse in={true} sx={{ backgroundColor: "#ffebee", padding: 2, borderRadius: 1, marginBottom: 2 }}>
                                    <Typography variant="subtitle1" color="error" fontWeight="bold">
                                        Error Details:
                                    </Typography>
                                    <Typography variant="body2" component="pre" sx={{ whiteSpace: "pre-wrap", maxHeight: "100px", overflow: "auto" }}>
                                        {this.state.error.toString()}
                                    </Typography>
                                </Collapse>
                            )}
                            {this.state.errorInfo && (
                                <Collapse in={true} sx={{ backgroundColor: "#f5f5f5", padding: 2, borderRadius: 1 }}>
                                    <Typography variant="subtitle2" color="textSecondary" fontWeight="bold">
                                        Error Stack Trace
                                    </Typography>
                                    <Typography variant="body2" component="pre" sx={{ whiteSpace: "pre-wrap", maxHeight: "150px", overflow: "auto", marginTop: 1 }}>
                                        {this.state.errorInfo.componentStack}
                                    </Typography>
                                </Collapse>
                            )}
                            <Typography variant="caption" color="textSecondary" display="block" sx={{ marginTop: 2 }}>
                                Time of Error: {this.state.timestamp}
                            </Typography>
                            <Button variant="contained" color="primary" onClick={this.handleRetry} sx={{ marginTop: 3 }}>
                                Try Again
                            </Button>
                        </CardContent>
                    </Card>
                </div>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;