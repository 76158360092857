import './App.css';
import {createBrowserHistory} from "history";
import routes, {renderRoutes} from './Routes';
import {GlobalStyles, StyledEngineProvider} from "@mui/material";
import {SnackbarProvider} from "notistack";
import {Provider as StoreProvider} from 'react-redux';
import {ThemeProvider} from "@mui/material/styles";
import ScrollReset from "./components/ScrollReset";
import useSettings from "./hooks/useSettings";
import {BrowserRouter} from 'react-router-dom'
import {createMuiTheme} from "./theme";
import {configureStore} from './store';
import {BreakpointProvider} from 'react-socks'
import React, {Suspense} from "react";
import SlashScreen from "./components/LoadingScreen";

const history = createBrowserHistory();
const store = configureStore();

function App() {
    const {settings} = useSettings();
    const theme = createMuiTheme({
        direction: settings.direction,
        responsiveFontSizes: settings.responsiveFontSizes,
        theme: settings.theme,
    });

    return (
        <Suspense fallback={<SlashScreen/>}>
            {/*<CheckConnection>*/}
                <BreakpointProvider>
                    <StoreProvider store={store}>
                        <ThemeProvider theme={theme}>
                            <StyledEngineProvider injectFirst>
                                <SnackbarProvider dense maxSnack={3}>
                                    <BrowserRouter history={history}>
                                        {/*<AuthProvider>*/}
                                        <GlobalStyles/>
                                        <ScrollReset/>
                                        {renderRoutes(routes)}
                                        {/*</AuthProvider>*/}
                                    </BrowserRouter>
                                </SnackbarProvider>
                            </StyledEngineProvider>
                        </ThemeProvider>
                    </StoreProvider>
                </BreakpointProvider>
            {/*</CheckConnection>*/}
        </Suspense>
    );
}

export default App;
