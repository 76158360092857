// customerActions.js
import {reposWebApi} from "./customerAPI";
import secureLocalStorage from "react-secure-storage";
import {logout, refreshToken} from "./sessionAction";
import qs from "querystring";

// Action types
export const CLEAR_DATA = "CLEAR_DATA";
export const ASSET_LIST = "ASSET_LIST";
export const IS_LOADING = "IS_LOADING";
export const REQUEST_ERROR = "REQUEST_ERROR";

// Snackbar default config
const DEFAULT_SNACKBAR_CONFIG = {
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "center"
    }
};

// Track token refresh status
let isRefreshing = false;
let refreshPromise = null;

/**
 * Gets the current auth token, ensuring it's properly formatted
 * @returns {string|null} The formatted auth token or null
 */
const getAuthToken = () => {
    const token = secureLocalStorage.getItem("access_token");
    if (!token) return null;

    // Ensure token is properly formatted (remove quotes if present)
    return typeof token === 'string' ? token.replace(/^["'](.*)["']$/, '$1') : token;
};

/**
 * Creates consistent authorization headers for all request types
 * @param {boolean} useFormData - Whether request uses form data
 * @returns {Object} Headers object
 */
const createHeaders = (useFormData = false) => {
    const token = getAuthToken();
    const headers = {};

    // Always set Authorization header the same way for all request types
    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }

    // Set appropriate content type
    if (useFormData) {
        headers['Content-Type'] = 'application/x-www-form-urlencoded';
    } else {
        headers['Content-Type'] = 'application/json';
    }

    return headers;
};

/**
 * Handles token refresh, ensuring only one refresh happens at a time
 * @param {Function} dispatch - Redux dispatch function
 * @returns {Promise} Promise that resolves when token is refreshed
 */
const handleTokenRefresh = (dispatch) => {
    if (isRefreshing) {
        return refreshPromise;
    }

    isRefreshing = true;
    refreshPromise = dispatch(refreshToken())
        .finally(() => {
            isRefreshing = false;
            refreshPromise = null;
        });

    return refreshPromise;
};

/**
 * Makes API request with proper authentication for all methods
 * @param {Object} config - Request configuration
 * @returns {Promise} Promise resolving to API response
 */
const makeApiRequest = async (config) => {
    const { url, method, params, data, useFormData } = config;

    // Create consistent headers for all request methods
    const headers = createHeaders(useFormData);

    // Process data if needed (for non-GET requests)
    const processedData = useFormData && data ? qs.stringify(data) : JSON.stringify(data);

    // Create axios config that works consistently for all methods
    const axiosConfig = { headers };
    if (params) {
        axiosConfig.params = params;
    }

    // Use the appropriate method
    switch (method.toLowerCase()) {
        case 'get':
            return reposWebApi.get(url, axiosConfig);
        case 'post':
            return reposWebApi.post(url, processedData, axiosConfig);
        case 'put':
            return reposWebApi.put(url, processedData, axiosConfig);
        case 'delete':
            // For DELETE, axios accepts config as the second parameter
            // Some implementations need data in the DELETE request, so we support that
            return processedData
                ? reposWebApi.delete(url, { ...axiosConfig, data: processedData })
                : reposWebApi.delete(url, axiosConfig);
        default:
            throw new Error(`Unsupported HTTP method: ${method}`);
    }
};

/**
 * Generic API request creator with authentication, error handling, and retry logic
 * @param {Object} options - Request configuration options
 * @param {string} options.url - API endpoint
 * @param {string} options.method - HTTP method (get, post, put, delete)
 * @param {Object} [options.params] - URL query parameters
 * @param {Object|string} [options.data] - Request body data
 * @param {boolean} [options.useFormData=false] - Whether to stringify data as form data
 * @param {boolean} [options.retry=true] - Whether to retry after token refresh
 * @param {Function} [options.onSuccess] - Success callback with response
 * @param {Function} [options.onError] - Error callback with error object
 * @param {Object} [options.actionTypes] - Redux action types to dispatch
 * @param {string} [options.actionTypes.start] - Action type for request start
 * @param {string} [options.actionTypes.success] - Action type for request success
 * @param {string} [options.actionTypes.failure] - Action type for request failure
 * @param {Object} [options.messages] - User notification messages
 * @param {string} [options.messages.success] - Success message
 * @param {string} [options.messages.error] - Error message
 * @param {string} [options.messages.unauthorized] - Unauthorized message
 * @param {Function} [options.mapResponse] - Transform API response before dispatching
 * @returns {Function} Redux thunk function
 */
export const createApiAction = (options) => {
    const {
        url,
        method = 'get',
        params,
        data,
        useFormData = false,
        retry = true,
        onSuccess,
        onError,
        actionTypes = {},
        messages = {},
        mapResponse = (res) => res?.data?.data,
    } = options;

    return (enqueueSnackbar = () => {}) => async (dispatch) => {
        // Dispatch loading action if specified
        if (actionTypes.start) {
            dispatch({ type: actionTypes.start });
        }

        try {
            // Make API request with consistent authentication handling
            const response = await makeApiRequest({
                url,
                method,
                params,
                data,
                useFormData
            });

            // Process successful response
            const responseData = mapResponse(response);

            // Dispatch success action if specified
            if (actionTypes.success) {
                dispatch({ type: actionTypes.success, payload: responseData });
            }

            // Show success message if provided
            if (messages.success) {
                enqueueSnackbar(response?.data?.data || messages.success, {
                    ...DEFAULT_SNACKBAR_CONFIG,
                    variant: "success"
                });
            }

            // Call success callback if provided
            if (onSuccess) {
                onSuccess(responseData, response);
            }

            return responseData;

        } catch (error) {
            console.log(`API Error (${method.toUpperCase()} ${url}):`, error?.response || error);

            // Handle unauthorized error (401)
            if (error?.response?.status === 401 && retry) {
                enqueueSnackbar(messages.unauthorized || "Reconnecting to server...", {
                    ...DEFAULT_SNACKBAR_CONFIG,
                    variant: "warning"
                });

                try {
                    // Try to refresh token
                    await handleTokenRefresh(dispatch);

                    // Check if we still have a token after refresh
                    const newToken = getAuthToken();
                    if (!newToken) {
                        throw new Error("Authentication failed after token refresh");
                    }

                    // Retry the same request with new token (but don't allow another retry)
                    const retryOptions = {
                        ...options,
                        retry: false // Prevent infinite retry loops
                    };

                    return dispatch(createApiAction(retryOptions)(enqueueSnackbar));
                } catch (refreshError) {
                    console.error("Token refresh failed:", refreshError);
                    dispatch(logout());

                    // Dispatch failure action if specified
                    if (actionTypes.failure) {
                        dispatch({
                            type: actionTypes.failure,
                            payload: { message: "Authentication failed" }
                        });
                    }

                    enqueueSnackbar("Session expired. Please login again.", {
                        ...DEFAULT_SNACKBAR_CONFIG,
                        variant: "error"
                    });

                    if (onError) {
                        onError(refreshError);
                    }

                    return null;
                }
            }

            // Handle bad request error (400)
            if (error?.response?.status === 400) {
                const errorMessage = error?.response?.data?.data || 'Request validation failed';

                enqueueSnackbar(errorMessage, {
                    ...DEFAULT_SNACKBAR_CONFIG,
                    variant: "warning"
                });
            } else {
                // Handle other errors
                enqueueSnackbar(messages.error || "Something went wrong", {
                    ...DEFAULT_SNACKBAR_CONFIG,
                    variant: "error"
                });
            }

            // Dispatch failure action if specified
            if (actionTypes.failure) {
                dispatch({
                    type: actionTypes.failure,
                    payload: error?.response?.data || { message: "Request failed" }
                });
            }

            // Call error callback if provided
            if (onError) {
                onError(error);
            }

            return  { error: error?.response?.data } || { error: "Request failed" };
        }
    };
};

/**
 * Example: Delete user API action
 * @param {number|string} userId - User ID to delete
 * @param {Function} enqueueSnackbar - Notification function
 * @returns {Function} Redux thunk function
 */
export const deleteUser = (userId, enqueueSnackbar) => {
    return createApiAction({
        url: `/customer/delete/${userId}`,
        method: 'delete',
        messages: {
            success: "User successfully deleted",
            error: "Failed to delete user"
        }
    })(enqueueSnackbar);
};

/**
 * Fetches customer asset list
 * @param {Function} enqueueSnackbar - Notification function
 * @param {Object} params - Query parameters
 * @returns {Function} Redux thunk function
 */
export const getCustomerAssetList = (enqueueSnackbar, params) => {
    return createApiAction({
        url: '/customer/list/assets',
        method: 'get',
        params,
        actionTypes: {
            start: IS_LOADING,
            success: ASSET_LIST,
            failure: REQUEST_ERROR
        },
        messages: {
            error: "Failed to fetch assets"
        }
    })(enqueueSnackbar);
};

export const clear_Data =()=>{
    return(dispatch)=>{
        dispatch({
            type: CLEAR_DATA,
        })
    }
}

/**
 * Adds a sub-customer
 * @param {Function} enqueueSnackbar - Notification function
 * @param {Object} params - Customer data
 * @returns {Function} Redux thunk function
 */
export const AddSubCustomer = (enqueueSnackbar, params) => {
    return createApiAction({
        url: '/customer/add/sec_customer',
        method: 'post',
        data: params,
        useFormData: true,
        messages: {
            success: "New user added successfully",
            error: "Failed to add user"
        }
    })(enqueueSnackbar);
};

/**
 * Validates mobile number and email
 * @param {Object} params - Validation parameters
 * @returns {Function} Redux thunk function
 */
export const checkMobileEmailAPI = (params) => {
    return createApiAction({
        url: '/customer/user_validation',
        method: 'get',
        params,
        actionTypes: {
            start: IS_LOADING
        },
        mapResponse: (response) => response.data
    })();
};

/**
 * Fetches customer asset list
 * @param {Function} enqueueSnackbar - Notification function
 * @param {Object} params - Query parameters
 * @param url
 * @param method
 * @returns {Function} Redux thunk function
 */
export const getData = (enqueueSnackbar, params, url, method) => {
    return createApiAction({
        url: url,
        method: method,
        params,
        actionTypes: {
            start: IS_LOADING,
            success: ASSET_LIST,
            failure: REQUEST_ERROR
        },
        messages: {
            error: "Failed to fetch assets"
        }
    })(enqueueSnackbar);
};

/**
 * Validates mobile number and email
 * @param {Object} params - Validation parameters
 * @param enqueueSnackbar
 * @returns {Function} Redux thunk function
 */
export const actionCall = (params,enqueueSnackbar) => {
    return createApiAction(params)(enqueueSnackbar);
};