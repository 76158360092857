import React, {useEffect, useState} from 'react';
import TopBar from './components/TopBar';
import {makeStyles, useTheme} from '@mui/styles';
import NavBar from './components/NavBar';
import SideBar from "./components/SideBar";
import PropTypes from "prop-types";
import Bottom from "./components/Bottom";
import {getNetworkError, responsive} from "../../actions";
import {useDispatch} from "react-redux";
import {useMediaQuery} from "@mui/material";
import useSound from 'use-sound';
import {Offline, Online} from "react-detect-offline";
import {useSnackbar} from "notistack";

const useStyles = makeStyles((theme) => ({
    root: {

        display: 'flex',
        height: '100vh',
        overflow: 'hidden',
        width: '100%',
        gridTemplateColumns: "auto auto auto"
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        paddingTop: 64,
        [theme.breakpoints.down('lg')]: {
            paddingBottom: 75,
        }
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden'
    },
    content: {
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto'
    }
}));
const DashboardLayout = ({children}) => {
    // ********************************** sound file ****************************************//
    const boopSfx = '/static/sound/interface-button.mp3'
    // *************************************************************************************** //
    const theme = useTheme();
    const classes = useStyles();
    const dispatch = useDispatch()
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);
    const greater = useMediaQuery(theme.breakpoints.between("lg", "xl"));
    const greaterThanMid = useMediaQuery(theme.breakpoints.between("md", "lg"));
    const smallToMid = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const lessThanSmall = useMediaQuery(theme.breakpoints.down("sm"));
    const small = useMediaQuery(theme.breakpoints.only("xs"));
    const [play] = useSound(boopSfx);
    const {enqueueSnackbar} = useSnackbar();


    useEffect(() => {
        dispatch(responsive(theme, greater, greaterThanMid, smallToMid, lessThanSmall, small));
    })

    // const [ipAddress, setIPAddress] = useState('')
    // const blockedIPs = ['103.207.11.150']; // Add blocked IPs here

    useEffect(() => {
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            // .then(data => setIPAddress(data.ip))
            .catch(error => console.log(error))
    }, [])


    useEffect(() => {
        function onlineHandler() {
            enqueueSnackbar("Welcome back to online", {
                variant: 'success', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            })
            dispatch(getNetworkError(false))
        }

        function offlineHandler() {
            enqueueSnackbar("You are offline now", {
                variant: 'error', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            })
            dispatch(getNetworkError(true))
        }

        window.addEventListener("online", onlineHandler);
        window.addEventListener("offline", offlineHandler);


        return () => {
            window.removeEventListener("online", onlineHandler);
            window.removeEventListener("offline", offlineHandler);
        };
        // eslint-disable-next-line
    }, []);

    // if (blockedIPs.includes(ipAddress)) {
    //     return <h1>Access denied. Your IP address is blocked.</h1>
    // }else

    return (
        <div className={classes.root}>
            <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} Online={Online} Offline={Offline}/>
            <NavBar
                onMobileClose={() => setMobileNavOpen(false)}
                openMobile={isMobileNavOpen}
                play={play}
            />
            <div className={classes.wrapper}>
                <div className={classes.contentContainer}>
                    <div className={classes.content}>
                        {children}
                    </div>
                </div>
            </div>
            <div>
                <SideBar
                    onMobileClose={() => setMobileNavOpen(false)}
                    openMobile={isMobileNavOpen}
                />
            </div>
            <Bottom onMobileClose={() => setMobileNavOpen(true)} play={play}/>
        </div>
    );
};

DashboardLayout.propTypes = {
    children: PropTypes.node
};

export default DashboardLayout;