// customerReducer.js
import * as actionTypes from "../actions";

const initialState = {
    hasMore: null,
    length: 0,
    isLoading: false,
    error: null,
    assetList: [],
};

function getUnique(array, key) {
    if (typeof key !== 'function') {
        const property = key;
        key = function (item) {
            return item[property];
        };
    }
    return Array.from(array.reduce(function (map, item) {
        const k = key(item);
        if (!map.has(k)) map.set(k, item);
        return map;
    }, new Map()).values());
}

const subCustomerReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.IS_LOADING:
            return {
                ...state,
                isLoading: true,
                error: null
            };

        case actionTypes.ASSET_LIST:
            return {
                ...state,
                isLoading: false,

                assetList: getUnique(state?.assetList?.concat(action?.payload?.results), "id") || [],
                hasMore: action?.payload?.next,
                length: action?.payload?.count
            };

        case actionTypes.REQUEST_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        case actionTypes.CLEAR_DATA:
            return {
                ...state,
                isLoading: true,
                assetList: [],
                hasMore: null,
                length: 0
            };

        default:
            return state;
    }
};

export default subCustomerReducer;