import React from 'react'
import ProfileIcon from "../../../../assets/svgs/ProfileIcon"
import {IconButton, SvgIcon, Tooltip} from '@mui/material'
import {Link as RouterLink} from 'react-router-dom';
import mixpanel from 'mixpanel-browser';


const Profile = () => {

    const handleProfileClick = () => {
        mixpanel.track('Profile Clicked');
    };


    return (
        <>
            <Tooltip title="Profile">
                <IconButton color="inherit"
                            component={RouterLink}
                            to={"/Profile"}
                            onClick={handleProfileClick}>
                    {/* onBoarding login  Profile*/}
                    <SvgIcon>
                        <ProfileIcon/>
                    </SvgIcon>
                </IconButton>
            </Tooltip>
        </>
    )
}

export default Profile